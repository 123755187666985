<script setup lang="ts">
import { upperFirst } from 'scule'
import type { CurrencyCode, OrderDetailLineItemFragment } from '#graphql-operations'
import Loading from '~/components/templates/Loading.vue'

const props = withDefaults(
  defineProps<{
    line: OrderDetailLineItemFragment
    currency: CurrencyCode
    editable?: boolean
  }>(),
  {
    editable: true,
  },
)

const emit = defineEmits<{
  (event: 'remove:orderLine', orderLine?: OrderDetailLineItemFragment): void
  (event: 'change:orderLine', orderLine?: OrderDetailLineItemFragment, quantity?: number): void
  (event: 'click:link', orderLine?: OrderDetailLineItemFragment): void
}>()

const isLoading = ref(false)

const { t } = useI18n()

const { removeOrderLine, adjustOrderLine } = useActiveOrder()
const priceFormatter = usePriceFormatter()

async function handleOrderLine(action: 'remove' | 'adjust', line: OrderDetailLineItemFragment, quantity?: number) {
  if (isLoading.value)
    return

  if (quantity !== undefined) {
    if (quantity < 1)
      return handleOrderLine('remove', line)
    else if (quantity > 99)
      quantity = 99
  }

  isLoading.value = true

  if (action === 'remove') {
    await removeOrderLine(line)
    emit('remove:orderLine', line)
  }
  else if (quantity !== undefined) {
    await adjustOrderLine(line, quantity)
    emit('change:orderLine', line, quantity)
  }

  isLoading.value = false
}

const saved = computed(() => {
  const { productVariant, unitPrice, quantity } = props.line
  const priceBeforeDiscount = productVariant?.priceBeforeDiscount
  if (priceBeforeDiscount && unitPrice !== priceBeforeDiscount) {
    return priceFormatter((priceBeforeDiscount - unitPrice) * quantity, props.currency)
  }
  return null
})
const discountPercentage = computed(() => {
  const priceBeforeDiscount = props.line.productVariant?.priceBeforeDiscount
  const currentPrice = props.line.unitPrice

  if (!priceBeforeDiscount || !currentPrice || priceBeforeDiscount <= currentPrice)
    return 0

  return Math.round(((priceBeforeDiscount - currentPrice) / priceBeforeDiscount) * 100)
})

const { editable, line } = toRefs(props)
</script>

<template>
  <li class="relative flex flex-col list-none">
    <Loading v-if="isLoading" />

    <div class="relative flex">
      <div class="relative h-20 w-20 flex-shrink-0 overflow-hidden rounded-md ring-1 ring-black/5">
        <NuxtImg
          v-if="line.featuredAsset?.preview"
          :src="line.featuredAsset.preview"
          :alt="line.productVariant.product.name"
          class="h-full w-full object-cover object-center"
          loading="lazy"
        />
      </div>

      <div class="ml-2 flex-auto sm:ml-3">
        <div class="hidden flex flex-col justify-between sm:flex-row">
          <div>
            <h3 class="text-slate-900 fw600">
              <NLink
                class="line-clamp-2 text-sm fw600 no-underline"
                :to="`/products/${line.productVariant.product.id}/${line.productVariant.product.slug}`"
                @click="$emit('click:link', line)"
              >
                {{ line.productVariant.product.name }}
              </NLink>
            </h3>

            <div v-if="line.productVariant.options.length" class="mt1 hidden">
              <p
                v-for="option in line.productVariant.options"
                :key="option.code"
                class="c-slate5 dark:c-slate7"
                n="xs"
              >
                {{ upperFirst(option.group.name) }}: {{ upperFirst(option.name) }}
              </p>
            </div>
          </div>

          <div class="mt2 flex flex-col fw600 sm:mt0 sm:text-right">
            <ins class="block text-context fw600 decoration-none" n="slate9 dark:slate4">
              {{ priceFormatter(line.linePrice, props.currency) }}
            </ins>
            <del
              v-if="line.productVariant?.priceBeforeDiscount && line.unitPrice !== line.productVariant.priceBeforeDiscount"
              class="text-context line-through" n="slate5 sm"
            >
              {{ priceFormatter(line.productVariant?.priceBeforeDiscount * line.quantity, props.currency) }}
            </del>
            <span v-if="discountPercentage > 0" class="ml-auto mr0 w-fit flex items-center justify-center whitespace-nowrap border-1 border-red-700 rounded-md border-solid bg-white px2 py-0.5 text-xs text-red-700 fw600 ring-1 ring-context/15 ring-inset">  - {{ discountPercentage }} %</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="editable" class="mt1 flex items-center justify-between gap-x-2">
      <div class="min-w-20 rounded-md bg-green-600/80 px2 py1 text-center text-xs text-white backdrop-blur-sm">
        {{ t('trustable.productcard.in_stock').toUpperCase() }}
      </div>
      <div class="flex items-center gap-2">
        <a
          class="text-context fw600"
          n="sm orange6 dark:orange4 hover:orange5"
          href=""
          @click.prevent="handleOrderLine('remove', line)"
        >
          <NIcon icon="i-hugeicons:delete-04" />
        </a>
        <span class="mt1 hidden c-slate5 dark:c-slate7" n="xs">
          {{ t('product.n_per_item.label', [priceFormatter(line.unitPrice, props.currency)]) }}
        </span>

        <div class="relative inline-block">
          <div
            data-testid="quantityStepper"
            class="w30 flex flex-row justify-between border n-border-300 rd-md bg-white p1 text-base n-text-content"
          >
            <button
              type="button"
              class="h6 w6 flex cursor-pointer items-center justify-center rd-4 border-none bg-white p0 hover:bg-slate-100"
              aria-disabled="false"
              aria-label="Decrease quantity"
              @click="handleOrderLine('adjust', line, line.quantity - 1)"
            >
              <NIcon icon="i-heroicons:minus-16-solid" />
            </button>

            <span class="mx-auto flex items-center text-sm fw700 n-text-heading">
              {{ line.quantity }}
            </span>

            <button
              type="button"
              class="h6 w6 flex cursor-pointer items-center justify-center rounded-4 border-none bg-white p0 hover:bg-slate-100"
              aria-disabled="false"
              aria-label="Increase quantity"
              @click="handleOrderLine('adjust', line, line.quantity + 1)"
            >
              <NIcon icon="i-heroicons:plus-16-solid" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
